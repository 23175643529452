<template>
  <div>
    <div class="trace-complement" @click="showForm">
      <i class="iconfont its_sm_luxian"></i>
      逆地址解析切换
    </div>
    <el-dialog
      title="逆地址解析切换"
      width="30%"
      class="order-dialog home-order-dialog"
      :modal-append-to-body="true"
      :visible.sync="visible"
      :modal="false"
      @closed="closeDia"
    >
      <el-form
        ref="form"
        label-width="90px"
        :model="ruleForm"
        :rules="rules"
        class="demo-form-inline"
        :inline="true"
      >
        <el-form-item label="切换模式：" prop="platformList">
          <el-select
              clearable
              v-model="ruleForm.platformList"
              placeholder="请选择"
            >
              <el-option
                v-for="item in platformList"
                :label="item.dictValue"
                :value="item.dictCode"
                :key="item.dictCode"
              ></el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="submit" type="primary" :disabled="submiting"
          >提交</el-button
        >
        <el-button @click="closeDia">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMapTypeAPI,
  setMapTypeAPI
} from "@/api/lib/api.js";

export default {
  data () {
    return {
      visible: false,
      ruleForm: {
        platformList:null
      },
      rules: {
        
        platformList:[
        { required: true, message: '请选择', trigger: 'blur' },
        ]
      },
      submiting: false,
      platformList:[
      {
          dictValue: '高德地图',
          dictCode: '0'
        },
        {
          dictValue: '天地图',
          dictCode: '1'
        },
      ]
    };
  },
  created () { },
  mounted () { 
    
  },
  methods: {
    getMapType(){
      getMapTypeAPI().then(res=>{
        if (res.code === 1000){
          this.ruleForm.platformList = res.data;
        }
      })
    },
    closeDia () {
      this.visible = false;
      this.$refs?.form?.resetFields();
    },
    showForm () {
      this.visible = true;
      this.getMapType();
      this.$refs?.form?.resetFields();
    },
    // 提交工单
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = { mapType : this.ruleForm.platformList};
          this.submiting = true;
          setMapTypeAPI(data)
            .then((res) => {
              if (res.code === 1000) {
                this.$message.success("修改成功");
                this.visible = false;
              } else {
                this.$message.error(res.msg ? res.msg : "修改失败");
              }
              this.submiting = false;
            })
            .catch((err) => {
              this.$message.error("提交失败 " + err?.msg ? err.msg : "");
              this.submiting = false;
            });
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.trace-complement {
  background: #fff;
  font-size: 14px;
  padding: 6px 10px 6px 10px;
  margin-right: 30px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: #176fd422;
    color: #007aff;
    // border:1px solid #007aff;
  }
}
.order-dialog {
  /deep/.el-dialog {
    border-radius: 15px;
    box-shadow: 0 5px 5px 4px #aaa8;
    .el-dialog__header {
      border: none !important;
    }
  }
}
.home-order-dialog {
  /deep/.el-dialog {
    margin: 0 !important;
    position: absolute;
    right: 20px;
    top: 70px;
  }
  /deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text {
    height: 100px !important;
  }
}
</style>